export default [
    {
        name: '[[[Strona główna]]]',
        short: '[[[Start]]]',
        route: 'dashboard',
        icon: 'fa-light fa-house-chimney',
        children: [
            {
                name: '[[[Profil użytkownika]]]',
                route: 'core-account-profile',
                icon: 'far fa-fw fa-user-circle'
            },
            {
                name: '[[[Zmiana hasła]]]',
                route: 'core-account-password',
                icon: 'far fa-fw fa-toggle-on'
            },
            {
                name: '[[[Centrum powiadomień]]]',
                route: 'core-account-notifications',
                icon: 'far fa-fw fa-envelope-open',
                query: {status: 'unreaded'},
                event: 'notifications::reload-list'
            },
            {
                name: '[[[Bezpieczeństwo]]]',
                route: 'core-account-security',
                icon: 'far fa-fw fa-phone-laptop'
            },
            {
                name: '[[[Tokeny dostępu]]]',
                route: 'core-pats',
                icon: 'far fa-fw fa-key'
            }
        ]
    },
    {
        name: '[[[Usługi]]]',
        short: '[[[Usługi]]]',
        icon: 'fa-light fa-server',
        namespace: 'Ideo.Core.Common.Application.Permissions.Saas',
        children: [
            {
                name: '[[[Klienci]]]',
                route: 'saas-tenants',
                icon: 'far fa-fw fa-users-class',
                auth: { all: ['TenantsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie klienta]]]',
                        route: 'saas-tenants-add',
                        auth: { all: ['TenantsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja klienta]]]',
                        route: 'saas-tenants-edit',
                        auth: { all: ['TenantsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Bazy danych]]]',
                route: 'saas-databases',
                icon: 'far fa-fw fa-database',
                auth: { all: ['DatabasesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie serwera]]]',
                        route: 'saas-databases-add',
                        auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja serwera]]]',
                        route: 'saas-databases-edit',
                        auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Serwery plików]]]',
                route: 'saas-storages',
                icon: 'far fa-fw fa-hdd',
                auth: { all: ['StoragesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie serwera]]]',
                        route: 'saas-storages-add',
                        auth: { all: ['StoragesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja serwera]]]',
                        route: 'saas-storages-edit',
                        auth: { all: ['StoragesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            }
        ]
    },
    {
        name: '[[[System]]]',
        short: '[[[System]]]',
        icon: 'fal fa-display',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        children: [
            {
                name: '[[[Administracja]]]',
                icon: 'far fa-fw fa-folder-user',
                children: [
                    {
                        name: '[[[Użytkownicy]]]',
                        route: 'core-users',
                        icon: 'far fa-fw fa-user',
                        auth: { all: ['UsersPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie użytkownika]]]',
                                route: 'core-users-add',
                                auth: { all: ['UsersPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja użytkownika]]]',
                                route: 'core-users-edit',
                                auth: { all: ['UsersPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Role i uprawnienia]]]',
                        route: 'core-roles',
                        icon: 'far fa-fw fa-user-shield',
                        auth: { all: ['RolesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie roli]]]',
                                route: 'core-roles-add',
                                auth: { all: ['RolesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja roli]]]',
                                route: 'core-roles-edit',
                                auth: { all: ['RolesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Organizacja]]]',
                        route: 'core-units',
                        icon: 'far fa-fw fa-sitemap',
                        auth: { all: ['OrganizationPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie działu]]]',
                                route: 'core-units-add',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja działu]]]',
                                route: 'core-units-edit',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zespoły]]]',
                        route: 'core-teams',
                        icon: 'far fa-fw fa-users',
                        auth: { all: ['TeamsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zespołu]]]',
                                route: 'core-teams-add',
                                auth: { all: ['TeamsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zespołu]]]',
                                route: 'core-teams-edit',
                                auth: { all: ['TeamsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zastępstwa]]]',
                        route: 'core-substitutions',
                        icon: 'far fa-fw fa-user-group',
                        auth: { all: ['SubstitutionsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zastępstwa]]]',
                                route: 'core-substitutions-add',
                                auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zastępstwa]]]',
                                route: 'core-substitutions-edit',
                                auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            // {
            //     name: '[[[Wyszukiwanie]]]',
            //     icon: 'far fa-fw fa-input-pipe',
            //     namespace: 'Ideo.Core.Common.Application.Permissions.Search',
            //     children: [
            //         {
            //             name: '[[[Lista indeksów]]]',
            //             auth: { all: ['SearchPermissions@Access'], any: [] },
            //             route: 'search-indices'
            //         },
            //         {
            //             name: '[[[Słowa wykluczone]]]',
            //             auth: { all: ['StopWordsPermissions@Access'], any: [] },
            //             route: 'search-stopwords'
            //         },
            //         {
            //             name: '[[[Synonimy]]]',
            //             auth: { all: ['SynonymsPermissions@Access'], any: [] },
            //             route: 'search-synonyms'
            //         }
            //     ]
            // },
            {
                name: '[[[Ustawienia]]]',
                icon: 'far fa-fw fa-cog',
                children: [
                    {
                        name: '[[[Konta użytkowników]]]',
                        route: 'core-accounts',
                        icon: 'far fa-fw fa-users',
                        auth: { all: ['AdminPermissions@Accounts'], any: [] }
                    },
                    {
                        name: '[[[Bezpieczeństwo]]]',
                        route: 'core-security',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Security'], any: [] }
                    },
                    {
                        name: '[[[ReCaptcha]]]',
                        route: 'core-recaptcha',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Recaptcha'], any: [] }
                    },
                    {
                        name: '[[[Poczta]]]',
                        route: 'core-mailing',
                        icon: 'fa-regular fa-envelope',
                        auth: { all: ['AdminPermissions@Mailing'], any: [] }
                    },
                    {
                        name: '[[[Powiadomienia]]]',
                        route: 'core-notifications-templates',
                        icon: 'far fa-fw fa-ballot-check',
                        auth: { all: ['NotificationsPermissions@Access'], any: [] },
                        children: [
                            {
                                name: '[[[Lista powiadomień]]]',
                                route: 'core-notifications-templates',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Dodaj szablon]]]',
                                route: 'core-notifications-templates-add',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj szablon]]]',
                                route: 'core-notifications-templates-edit',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false,
                            }
                        ]
                    },
                    {
                        name: '[[[Źródła LDAP]]]',
                        route: 'core-ldap',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie źródła]]]',
                                route: 'core-ldap-add',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja źródła]]]',
                                route: 'core-ldap-edit',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Bramka SMS]]]',
                        route: 'core-sms',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Sms'], any: [] }
                    },
                    {
                        name: '[[[Ustawienia sesji]]]',
                        route: 'core-session-settings',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Session'], any: [] }
                    },
                    {
                        name: '[[[Paleta kolorów]]]',
                        route: 'core-colors',
                        icon: 'far fa-fw fa-palette',
                        auth: { all: ['AdminPermissions@Colors'], any: [] }
                    },
                    {
                        name: '[[[Asystent AI]]]',
                        route: 'core-ai',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                    },
                    {
                        name: '[[[Tłumaczenia]]]',
                        route: 'core-translations',
                        icon: 'far fa-fw fa-subtitles',
                        auth: { all: ['AdminPermissions@Translations'], any: [] }
                    },
                    {
                        name: '[[[Asystent]]]',
                        route: 'core-ai-assistant-add',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Asystent]]]',
                        route: 'core-ai-assistant-edit',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Pliki Asystenta]]]',
                        route: 'core-ai-assistant-file-add',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Pliki Asystent]]]',
                        route: 'core-ai-assistant-file-edit',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Funkcje Asystenta]]]',
                        route: 'core-ai-assistant-function',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Funkcje Asystenta]]]',
                        route: 'core-ai-assistant-function-add',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Funkcje Asystent]]]',
                        route: 'core-ai-assistant-function-edit',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Monitoring]]]',
                        route: 'core-monitoring',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Monitoring'], any: [] }
                    },
                    {
                        name: '[[[Logi]]]',
                        route: 'core-logs-targets',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                        children: [
                            {
                                name: '[[[Logi]]]',
                                route: 'core-logs-targets',
                                icon: 'far fa-fw fa-shield-alt',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-targets-add',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-targets-edit',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Logi]]]',
                                route: 'core-logs-rules',
                                icon: 'far fa-fw fa-shield-alt',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-rules-add',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-rules-edit',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            }
                        ]
                    },

                ]
            },
            {
                name: '[[[Monitoring]]]',
                icon: 'far fa-fw fa-analytics',
                children: [
                    {
                        name: '[[[Historia logowania]]]',
                        route: 'core-authlog',
                        icon: 'far fa-fw fa-file-import',
                        auth: { all: ['AdminPermissions@Authlog'], any: [] }
                    },
                    {
                        name: '[[[Rejestr zmian]]]',
                        route: 'core-changelog',
                        icon: 'far fa-fw fa-file-signature',
                        auth: { all: ['AdminPermissions@Changelog'], any: [] },
                        children: [
                            {
                                name: '[[[Szczegóły zdarzenia]]]',
                                route: 'core-changelog-diff',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Harmonogram zadań]]]',
                        route: 'core-scheduler',
                        icon: 'far fa-fw fa-clock',
                        auth: { all: ['SchedulerPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Historia zadania]]]',
                                route: 'core-scheduler-history',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły zadania]]]',
                                route: 'core-scheduler-details',
                                visible: false
                            }
                        ]
                    }
                    // {
                    //     name: '[[[Monitor kolejek]]]',
                    //     route: 'core-queues',
                    //     icon: 'far fa-fw fa-clock',
                    //     auth: { all: ['MessagePermissions@View'], any: [] },
                    //     children: [
                    //         {
                    //             name: "[[[Szczegóły zadania]]]",
                    //             route: 'core-queues-details',
                    //             visible: false
                    //         }
                    //     ]
                    // }
                ]
            },
        ]
    },
    // {
    //     name: '[[[Design Studio]]]',
    //     icon: 'fa-light fa-circle-nodes', // fa-gear
    //     namespace: 'Ideo.Core.Common.Application.Permissions.Studio',
    //     children: [
    //         {
    //             name: '[[[Formularze]]]',
    //             icon: 'far fa-fw fa-table-layout',
    //             auth: { all: ['FormsPermissions@Access'], any: [] },
    //             route: 'core-forms',
    //             children: [
    //                 {
    //                     name: '[[[Dodanie formularza]]]',
    //                     route: 'core-forms-add',
    //                     auth: { all: [], any: ['FormsPermissions@Edit', 'FormsPermissions@EditAll'] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja formularza]]]',
    //                     route: 'core-forms-edit',
    //                     auth: { all: [], any: ['FormsPermissions@Edit', 'FormsPermissions@EditAll'] },
    //                     visible: false
    //                 }
    //             ]
    //         },
    //         {
    //             name: '[[[Komponenty]]]',
    //             icon: 'far fa-fw fa-grid-round-2-plus',
    //             auth: { all: ['ComponentsPermissions@Access'], any: [] },
    //             route: 'core-components',
    //             children: [
    //                 {
    //                     name: '[[[Dodanie komponentu]]]',
    //                     route: 'core-components-add',
    //                     auth: { all: ['ComponentsPermissions@Edit'], any: [] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja komponentu]]]',
    //                     route: 'core-components-edit',
    //                     auth: { all: ['ComponentsPermissions@Edit'], any: [] },
    //                     visible: false
    //                 }
    //             ]
    //         },
    //         {
    //             name: '[[[Źródła danych]]]',
    //             route: 'core-datasources',
    //             icon: 'far fa-fw fa-database',
    //             auth: { all: ['DataSourcesPermissions@Access'], any: [] },
    //             children: [
    //                 {
    //                     name: '[[[Dodanie źródła danych]]]',
    //                     route: 'core-datasources-add',
    //                     auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja źródła danych]]]',
    //                     route: 'core-datasources-edit',
    //                     auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 }
    //             ]
    //         },
    //         {
    //             name: '[[[Moduły]]]',
    //             icon: 'far fa-fw fa-layer-group',
    //             auth: { all: ['ModulesPermissions@Access'], any: [] },
    //             route: 'core-modules',
    //             children: [
    //                 {
    //                     name: '[[[Dodanie modułu]]]',
    //                     route: 'core-modules-add',
    //                     auth: { all: ['ModulesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja modułu]]]',
    //                     route: 'core-modules-edit',
    //                     auth: { all: ['ModulesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 }
    //             ]
    //         },
    //         // {
    //         //     name: '[[[Headless API]]]',
    //         //     icon: 'far fa-fw fa-brackets-curly',
    //         //     auth: { all: ['HeadlessPermissions@Access'], any: [] },
    //         //     url: '#',
    //         //     route: 'core-headless',
    //         //     children: [
    //         //         {
    //         //             name: '[[[Dodanie endpointów]]]',
    //         //             route: 'core-headless-add',
    //         //             auth: { all: ['HeadlessPermissions@Edit'], any: [] },
    //         //             visible: false
    //         //         },
    //         //         {
    //         //             name: '[[[Edycja endpointów]]]',
    //         //             route: 'core-headless-edit',
    //         //             auth: { all: ['HeadlessPermissions@Edit'], any: [] },
    //         //             visible: false
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     name: '[[[Webhooks]]]',
    //         //     icon: 'far fa-fw fa-gears',
    //         //     auth: { all: ['WebhooksPermissions@Access'], any: [] },
    //         //     url: '#',
    //         //     route: 'core-webhooks',
    //         //     children: [
    //         //         {
    //         //             name: '[[[Dodanie webhooka]]]',
    //         //             route: 'core-webhooks-add',
    //         //             auth: { all: ['WebhooksPermissions@Edit'], any: [] },
    //         //             visible: false
    //         //         },
    //         //         {
    //         //             name: '[[[Edycja webhooka]]]',
    //         //             route: 'core-webhooks-edit',
    //         //             auth: { all: ['WebhooksPermissions@Edit'], any: [] },
    //         //             visible: false
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     name: '[[[Automatyzacja]]]',
    //         //     icon: 'far fa-fw fa-gears',
    //         //     auth: { all: ['AutomationPermissions@Access'], any: [] },
    //         //     url: '#',
    //         //     route: 'core-automation',
    //         //     children: [
    //         //         {
    //         //             name: '[[[Dodanie scenariusza]]]',
    //         //             route: 'core-automation-add',
    //         //             auth: { all: ['AutomationPermissions@Edit'], any: [] },
    //         //             visible: false
    //         //         },
    //         //         {
    //         //             name: '[[[Edycja scenariusza]]]',
    //         //             route: 'core-automation-edit',
    //         //             auth: { all: ['AutomationPermissions@Edit'], any: [] },
    //         //             visible: false
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     name: '[[[Dokumenty]]]',
    //         //     icon: 'far fa-fw fa-notebook',
    //         //     auth: { all: ['EntriesPermissions@Access'], any: [] },
    //         //     route: 'core-entries',
    //         //     children: [
    //         //         {
    //         //             name: '[[[Dodanie dokumentu]]]',
    //         //             route: 'core-entries-add',
    //         //             auth: { all: [], any: ['EntriesPermissions@Edit', 'EntriesPermissions@EditAll'] },
    //         //             visible: false
    //         //         },
    //         //         {
    //         //             name: '[[[Edycja dokumentu]]]',
    //         //             route: 'core-entries-edit',
    //         //             auth: { all: [], any: ['EntriesPermissions@Edit', 'EntriesPermissions@EditAll'] },
    //         //             visible: false
    //         //         }
    //         //     ]
    //         // }
    //     ]
    // },
    // {
    //     name: '[[[Moduły]]]',
    //     route: 'core-docs',
    //     icon: 'fa-light fa-layer-group',
    //     namespace: 'Ideo.Core.Common.Application.Permissions.Storage',
    //     auth: { all: [], any: [] }
    // },
    {
        name: '[[[Repozytorium plików]]]',
        short: '[[[Pliki]]]',
        route: 'filemanager',
        preventCloseMenu: true,
        icon: 'fa-light fa-folder-closed',
        namespace: 'Ideo.Core.Common.Application.Permissions.Storage',
        auth: { all: ['StoragePermissions@Access'], any: [] }
    },
    {
        name: '[[[Licencje]]]',
        route: 'clients',
        icon: 'fa-light fa-users',
        children: [
            {
                name: '[[[Klienci]]]',
                route: 'clients',
                icon: 'fa-solid fa-id-card-clip',
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'clients-details',
                        visible: false
                    },
                    {
                        name: '[[[Dodaj klienta]]]',
                        route: 'clients-add',
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Aplikacje]]]',
                icon: 'fa-sharp fa-solid fa-arrows-rotate',
                route: 'application-versions',
                namespace: 'Ideo.Core.Licences.Applications.Application.Permissions.ApplicationsVersions',
                auth: { all: ['ApplicationsVersionsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'application-versions-edit',
                        visible: false
                    },
                    {
                        name: '[[[Dodaj]]]',
                        route: 'application-versions-add',
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Pluginy Marketplace]]]',
                icon: 'fa-solid fa-puzzle-piece-simple',
                route: 'application-plugins',
                namespace: 'Ideo.Core.Licences.Applications.Application.Permissions.ApplicationsPlugins',
                auth: { all: ['ApplicationsPluginsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'application-plugins-edit',
                        visible: false,
                        children: [
                            {
                                name: '[[[Wersje pluginu]]]',
                                route: 'application-plugins-versions',
                                visible: false
                            },
                            {
                                name: '[[[Dodaj wersję pluginu]]]',
                                route: 'application-plugins-versions-add',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'application-plugins-versions-edit',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Dodaj plugin]]]',
                        route: 'application-plugins-add',
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Instalatory]]]',
                icon: 'fa-solid fa-display-arrow-down',
                route: 'installers',
                namespace: 'Ideo.Core.Licences.Installer.Application.Permissions',
                auth: { all: ['InstallerPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'installers-edit',
                        visible: false
                    },
                    {
                        name: '[[[Dodaj instalator]]]',
                        route: 'installers-add',
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Agenty]]]',
                icon: 'fa-sharp fa-solid fa-gears',
                route: 'managers',
                namespace: 'Ideo.Core.Licences.Manager.Application.Permissions',
                auth: { all: ['ManagerPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'managers-edit',
                        visible: false
                    },
                    {
                        name: '[[[Dodaj wersję agenta]]]',
                        route: 'managers-add',
                        visible: false
                    }
                ]
            }
        ]
    },
    {
        name: '[[[Kosz]]]',
        short: '[[[Kosz]]]',
        route: 'trash',
        preventCloseMenu: true,
        icon: 'fa-light fa-trash',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        auth: { all: ['TrashPermissions@View'], any: [] },
        children: [
            {
                name: '[[[Grupa]]]',
                route: 'trash-group',
                visible: false
            }
        ]
    }
];
